<!-- Hero Start -->
<section class="bg-home rtl-personal-hero bg-light d-flex align-items-center"
  style="background:url('assets/images/personal/juannovoa3.png') center" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-9">
        <div class="title-heading mt-4">
          <h1 class="display-3 fw-bold mb-3">Intentando ser<br>
            <ngx-typed-js [strings]="['Founder', 'Tech leader', 'Developer' , 'Photographer']" [shuffle]="true"
              [typeSpeed]="100" [loop]="true">
              <span class="element typing text-primary"></span>
            </ngx-typed-js>
          </h1>
          <p class="para-desc text-muted">Aproveche, mire, esta es una ventana a lo que soy, lo que me gusta hacer y en
            lo que suelo ocupar la mayor parte de mi tiempo, por que todo esto lo hago con pasíon</p>
          <div class="mt-4 pt-2">
            <a [ngxScrollTo]="'#zoneone'" class="btn btn-pills btn-outline-primary mt-2 me-2"><i
                class="uil uil-cloud-download "></i> Saber mas</a>
            <a [ngxScrollTo]="'#photografer'" class="btn btn-pills btn-soft-primary mt-2"><i class="uil uil-camera"></i>
              Ver fotos
            </a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- ZoneOne Start -->
<section class="section" id="zoneone">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 order-2 mt-sm-0 pt-2 pt-sm-0">
        <img src="assets/images/personal/zone-logo.png" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-1 order-md-1">
        <div class="section-title me-lg-5">
          <h1 class="text-primary"><i class="uil uil-list-ui-alt"></i></h1>
          <h4 class="title mb-4">ZoneOne Founder <br> CEO</h4>
          <p class="text-muted">Fundador en 2022 de la aplicación que te permite acercate facilmente a otras personas,
            en eventos presenciales.
            Crea zonas en las cuales segmentar tu asistencia, permitiendo a los usuarios contactar con diferentes roles.
          </p>
          <ul class="list-unstyled text-muted">
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Quieres conversar con el presidente de una compañia ?
            </li>
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Quieres conectar con el director de area de una empresa?
            </li>
            <li>
              <i-feather name="arrow-right-circle" class="fea icon-sm text-primary me-2"></i-feather>
              Quieres poder conocer alguien en algun restaurante, bar o incluso en un concierto?
            </li>
          </ul>
          <a href="http://zoneone.com.co" class="btn btn-pills btn-soft-warning mt-2">
            Conoce ZoneOne <i class="uil uil-arrow-circle-right"></i>
          </a>
          <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="mdi mdi-chevron-right"></i></a> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container mt-100 mt-60" id="tech">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/company/about2.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="6" [from]="0"
                [duration]="3"></span>+</span>
            <span class="h6 align-self-end ms-2">Años <br> Experiencia</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">Tech Leader</h4>
            <p class="text-muted">Lider de tecnolgia, developer fullstack, con experencia en fomentar el crecimiento
              <span class="text-primary fw-bold">personal y profesional</span> de cada de los miembros del
              equipos empalmando las actividades coorporativas con el cumplimento de objetivos organizaciones,
              consolidando una <span class="text-primary fw-bold">vision estrategica</span> en cada integrante, para
              fortalecer el campo de acción e impacto de la compañia como de sus colaboradores.
            </p>
            <a href="javascript:void(0)" class="btn btn-pills btn-soft-primary mt-3">Contactame</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="section-title">
          <h4 class="title">Habilidades</h4>
          <h6 class="text-primary mb-4">Fan de crear soluciones tecnologicas</h6>
          <p class="text-muted mb-0"> FullStack Developer con amplios conocimientos en la construcción de soluciones de
            software de calidad y una gran experiencia de usuario para ambientes web, móvil y de escritorio.
            Desarrollador líder de soluciones tecnológicas de vanguardia, con énfasis en planeación, desarrollo y
            despliegue bajo framework .NET. </p>
          <div class="mt-4">

          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="ms-md-4">
          <div class="progress-box">
            <h6 class="title text-muted">Visión Técnica</h6>
            <div class="progress">
              <div class="progress-bar position-relative bg-primary" style="width:84%;">
                <div class="progress-value d-block text-muted h6">84%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-muted">Motivación de equipos</h6>
            <div class="progress">
              <div class="progress-bar position-relative bg-primary" style="width:75%;">
                <div class="progress-value d-block text-muted h6">75%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-muted">Management 3.0</h6>
            <div class="progress">
              <div class="progress-bar position-relative bg-primary" style="width:79%;">
                <div class="progress-value d-block text-muted h6">79%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-muted">Desarrollo fullstack</h6>
            <div class="progress">
              <div class="progress-bar position-relative bg-primary" style="width:90%;">
                <div class="progress-value d-block text-muted h6">90%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-muted">Cloud Services</h6>
            <div class="progress">
              <div class="progress-bar position-relative bg-primary" style="width:73%;">
                <div class="progress-value d-block text-muted h6">73%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

</section>

<!-- Client start -->
<section class="py-4 border-bottom">
  <div class="container py-4">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->



<!-- <section class="section pb-0" id="posted">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Post de interes</h4>
          <p class="text-muted para-desc mb-0">Aqui suelo poner temas de interes, comentarios, en incluso opiniones de
            temas que pueden aportar <span class="text-primary fw-bold">valor</span>
            para las personas que se desempeñan desde roles parecidos al mio.</p>
        </div>
      </div>
    </div>
    <app-blog [blogData]="blogData"></app-blog>
  </div>

  <div class="container mt-100 mt-60" id="photos">
    <div class="row">
      <div class="col-12">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Tambien dejo mis fotos aquí</h4>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let item of workList; let i=index">
        <div class="card border-0 work-container work-modern position-relative d-block overflow-hidden rounded">
          <div class="card-body p-0">
            <img src="{{item.image }}" class="img-fluid" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-white title">{{item.title}}</a></h5>
              <h6 class="text-light tag mb-0">{{item.category}}</h6>
            </div>
            <div class="icons text-center">
              <a (click)="open(i)" class="text-primary work-icon bg-white d-inline-block rounded-pill mfp-image">
                <i-feather name="camera" class="fea icon-sm"></i-feather>
              </a>
            </div>
          </div>
        </div>
      </div>


      <div class="col-12 mt-4 pt-2">
        <a routerLink="/portfolio-modern-three" class="btn btn-outline-primary">See More <i
            class="mdi mdi-chevron-right"></i></a>
      </div>

    </div>

  </div>
</section> -->


<!-- seccion de contacto -->
<section class="section pb-0" id="contact">
  <div class="container mt-60" id="contact">
    <div class="row">

      <div class="col-md-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <a href="mailto:juand.novoam@gmail.com" class="btn btn-icon btn-outline-warning mt-2 ms-1">
              <i-feather name="mail" class="icons"></i-feather>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <a href="https://www.facebook.com/juanchox1" target="_blank"
              class="btn btn-icon btn-outline-primary mt-2 ms-1">
              <i-feather name="facebook" class="icons"></i-feather>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <a href="https://www.instagram.com/juandnovoam/" target="_blank"
              class="btn btn-icon btn-outline-danger mt-2 ms-1">
              <i-feather name="instagram" class="icons"></i-feather>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <a href="https://www.linkedin.com/in/juan-david-novoa-morales-71b997166/" target="_blank"
              class="btn btn-icon btn-outline-primary mt-2 ms-1">
              <i-feather name="linkedin" class="icons"></i-feather>
            </a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Contacto !</h4>
            <div class="custom-form mt-3">
              <form method="post" name="myForm" onsubmit="return validateForm()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Tu Nombre <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Tu Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Asunto</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Mensaje <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary">Enviar Mensaje</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="card border-0">
          <div class="card-body p-0">
            <img src="assets/images/contact.svg" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


</section>
<!-- fin seccion de contacto -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->