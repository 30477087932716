import { Component, OnInit } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  /**
   * Clients Logo
   */
  clients_logo: client[] = [
    {
      image: "assets/images/client/angular.png"
    },
    {
      image: "assets/images/client/netcore.png"
    },
    {
      image: "assets/images/client/ionic.png"
    },
    {
      image: "assets/images/client/azure.png"
    },
    {
      image: "assets/images/client/aws.png"
    },
    {
      image: "assets/images/client/devops.png"
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
