<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Gods Privacy Policy </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 18th Ago,
              2023</li>
          </ul>
          <div class="page-next">
            <!-- <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy</li>
              </ul>
            </nav> -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Overview :</h5>
            <p class="text-muted">La presente Política de Privacidad describe cómo Gods ("nosotros", "nuestro" o "la
              Aplicación") recopila, utiliza, almacena y comparte información personal de los usuarios
              ("tú" o "usuario") de nuestra aplicación móvil y plataforma en línea ("la Aplicación"). Te
              recomendamos leer detenidamente esta Política de Privacidad antes de utilizar la
              Aplicación. Al utilizar la Aplicación, estás aceptando las prácticas descritas en esta
              política</p>

            <!-- <h5 class="card-title">We use your information to :</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Digital Marketing Solutions for Tomorrow
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Our Talented & Experienced Marketing Agency
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Create your own skin to match your brand
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Digital Marketing Solutions for Tomorrow
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Our Talented & Experienced Marketing Agency
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Create your own skin to match your brand
              </li>
            </ul> -->

            <h3 class="card-title">1. Información que Recopilamos</h3>

            <h5 class="card-title">Información Personal :</h5>
            <p class="text-muted">Cuando te registras en la Aplicación, podemos recopilar
              información como tu nombre, dirección de correo electrónico, fecha de nacimiento,
              género y fotografía de perfil.</p>

            <h5 class="card-title"> Información de Perfil :</h5>
            <p class="text-muted">Puedes proporcionar información adicional en tu perfil,
              como tus intereses, preferencias y una breve descripción personal. Esta información es
              opcional y la proporcionas bajo tu responsabilidad.</p>

            <h5 class="card-title"> Datos de Uso: </h5>
            <p class="text-muted">Recopilamos información sobre cómo interactúas con la Aplicación,
              incluyendo tus interacciones con otros usuarios, mensajes, me gusta, coincidencias y
              otros datos de uso similares.</p>


            <h5 class="card-title"> Información de Dispositivo :</h5>
            <p class="text-muted">Podemos recopilar información sobre el dispositivo
              que utilizas para acceder a la Aplicación, como la versión del sistema operativo, la
              dirección IP, el tipo de navegador y la información de la red móvil.
            </p>

            <h5 class="card-title"> 2. Uso de la Información:</h5>
            <p class="text-muted">2.1. Utilizamos la información recopilada para brindarte y mejorar nuestros servicios,
              incluyendo:
              <br>
              Facilitar la creación y administración de tu perfil.
              <br>
              Proporcionar funcionalidades de búsqueda y coincidencia basadas en tus preferencias.
              <br>
              Facilitar la comunicación entre usuarios.
              <br>
              Analizar y mejorar la eficacia de nuestras características y ofertas.
              <br>
              2.2. Podemos utilizar tu dirección de correo electrónico para enviarte actualizaciones,
              novedades y promociones relacionadas con la Aplicación. Puedes optar por no recibir
              estas comunicaciones en cualquier momento.
            </p>

            <h5 class="card-title"> 3. Compartir de Información </h5>
            <p class="text-muted"> 3.1 No compartimos tu información personal con terceros sin tu consentimiento,
              excepto en las siguientes situaciones:
              Con otros usuarios de la Aplicación cuando haya una coincidencia mutua.
              <br>
              3.2
              Cuando sea necesario para cumplir con la ley, regulaciones o procesos legales.
              Para proteger nuestros derechos, privacidad, seguridad o propiedad, así como los tuyos
              o los de terceros.
              En el caso de una fusión, adquisición o venta de activos, donde la información del
              usuario pueda ser transferida a los nuevos propietarios.
            </p>

            <h5 class="card-title"> 4. Compartir de Información </h5>
            <p class="text-muted"> 4.1. Implementamos medidas de seguridad razonables para proteger la información
              personal contra pérdida, acceso no autorizado, divulgación o alteración.
            </p>

            <h5 class="card-title"> 5. Menores de Edad </h5>
            <p class="text-muted"> 5.1. La Aplicación no está destinada a menores de 18 años. No recopilamos
              intencionalmente información de menores de edad. Si eres padre o tutor y crees que tu
              hijo nos ha proporcionado información personal, contáctanos de inmediato.
            </p>

            <h5 class="card-title"> 6. Cambios en la Política de Privacidad </h5>
            <p class="text-muted"> 6.1. Podemos modificar esta Política de Privacidad en cualquier momento. Te
              notificaremos sobre cambios importantes a través de la Aplicación o por otros medios
              para que puedas revisar los cambios antes de continuar utilizando nuestros servicios.
            </p>

            <h5 class="card-title"> 7. Contacto </h5>
            <p class="text-muted"> 7.1. Si tienes preguntas, inquietudes o solicitudes relacionadas con esta Política de
              Privacidad, contáctanos en [dirección de correo electrónico de contacto].
              Al utilizar la Aplicación, aceptas las condiciones de esta Política de Privacidad.
            </p>

            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->